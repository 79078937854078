var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      style: {
        marginTop: "-50px",
        height: "100vh",
        width: "100%",
        backgroundImage: _vm.backgroundImage,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover"
      },
      attrs: {
        fluid: "",
        "fill-height": "",
        height: "100vh",
        id: "principal",
        "data-cy": "signinCard"
      }
    },
    [
      _c(
        "v-col",
        {
          staticClass: "ma-auto",
          attrs: { cols: "10", sm: "10", md: "10", lg: "4" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mt-10",
              attrs: { color: "rgb(255, 255, 255, 0.95)" }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "ma-auto pt-10",
                  attrs: { cols: "8", sm: "4", md: "4", lg: "8" }
                },
                [
                  _c("v-img", {
                    attrs: {
                      "align-center": "",
                      "justify-center": "",
                      "data-cy": "signinLogo",
                      src: _vm.logo,
                      alt: "logo-recuperi"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "ma-auto", attrs: { cols: "12", lg: "10" } },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.checkformSubmit()
                            }
                          },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "prepend-inner-icon": "person",
                              name: "email",
                              label: "Digite seu login de usuário",
                              type: "email",
                              rules: _vm.emailRules,
                              required: "",
                              small: "",
                              solo: "",
                              "data-cy": "signinEmailField"
                            },
                            model: {
                              value: _vm.email,
                              callback: function($$v) {
                                _vm.email = $$v
                              },
                              expression: "email"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "prepend-inner-icon": "lock",
                              name: "password",
                              label: "Digite sua senha",
                              type: _vm.show1 ? "text" : "password",
                              "append-icon": _vm.show1
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              "data-cy": "signinPasswordField",
                              rules: _vm.passwordRules,
                              small: "",
                              solo: "",
                              required: ""
                            },
                            on: {
                              "click:append": function($event) {
                                _vm.show1 = !_vm.show1
                              }
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "grey--text mt-4",
                              attrs: { text: "", "x-small": "", dense: "" },
                              on: {
                                click: function($event) {
                                  _vm.dialogRecuperar = !_vm.dialogRecuperar
                                }
                              }
                            },
                            [_vm._v("Esqueceu a senha?")]
                          ),
                          _c("v-checkbox", {
                            attrs: {
                              "data-cy": "signinTermosCheckbox",
                              "x-small": "",
                              required: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _vm._v(
                                          " Declaro estar de acordo com os "
                                        ),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href:
                                                              "https://recuperi.com.br/institucional/termos-de-uso"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Termos de Uso "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [_vm._v(" Termos de uso ")]
                                        ),
                                        _vm._v(" , "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href:
                                                              "https://recuperi.com.br/institucional/politica-de-privacidade"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Política de Privacidade "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [_vm._v(" Política de Privacidade ")]
                                        ),
                                        _vm._v(" e "),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "activator",
                                                fn: function({ on }) {
                                                  return [
                                                    _c(
                                                      "a",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            target: "_blank",
                                                            href:
                                                              "https://recuperi.com.br/institucional/codigo-de-etica"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                            }
                                                          }
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          " Código de Ética "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          },
                                          [_vm._v(" Código de Ética ")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.checkboxTermos,
                              callback: function($$v) {
                                _vm.checkboxTermos = $$v
                              },
                              expression: "checkboxTermos"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.checkForm(),
                            block: "",
                            "data-cy": "signinSubmitBtn",
                            loading: _vm.load_login
                          },
                          on: { click: _vm.submit }
                        },
                        [
                          _vm._v(" Entrar "),
                          _c("v-icon", { staticClass: "ml-1" }, [
                            _vm._v("chevron_right")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.dialogRecuperar,
            callback: function($$v) {
              _vm.dialogRecuperar = $$v
            },
            expression: "dialogRecuperar"
          }
        },
        [
          _c(
            "v-card",
            [
              this.email == "" || !/.+@.+/.test(this.email)
                ? _c("v-card-text", { staticClass: "pt-5 mb-n10" }, [
                    _vm._v("Informe um e-mail válido")
                  ])
                : _c(
                    "v-card-text",
                    {
                      staticClass: "px-4 pt-4 pb-0",
                      staticStyle: { "text-align": "justify" }
                    },
                    [
                      _vm._v("Vamos verificar o login "),
                      _c("b", [_vm._v(_vm._s(_vm.email))]),
                      _vm._v(
                        " no sistema e, se pertencer a um usuário válido, enviaremos um e-mail para o endereço vinculado a ele."
                      )
                    ]
                  ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  this.email != "" && /.+@.+/.test(this.email)
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green darken-1",
                            text: "",
                            loading: _vm.loadingEnviaEmail
                          },
                          on: {
                            click: function($event) {
                              return _vm.enviaEmail(_vm.email)
                            }
                          }
                        },
                        [_vm._v("Confirmar")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogRecuperar = !_vm.dialogRecuperar
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px" },
          model: {
            value: _vm.dialogTokenExpirou,
            callback: function($$v) {
              _vm.dialogTokenExpirou = $$v
            },
            expression: "dialogTokenExpirou"
          }
        },
        [
          _c("v-card", { staticClass: "text-center" }, [
            _c(
              "div",
              [
                _c(
                  "v-card-text",
                  { staticClass: "title" },
                  [
                    _c("v-icon", { staticClass: "ma-1" }, [
                      _vm._v("mdi-alert-circle-outline")
                    ]),
                    _vm._v("Sua sessão expirou devido a inatividade ")
                  ],
                  1
                ),
                _c("v-card-text", [
                  _vm._v("Faça login novamente para ter acesso ao painel")
                ]),
                _c(
                  "v-btn",
                  {
                    staticClass: "ma-3 pa-3 primary white--text",
                    on: { click: _vm.desabilitaDialogToken }
                  },
                  [
                    _c("v-icon", { staticClass: "ma-1" }, [
                      _vm._v("mdi-chevron-right")
                    ]),
                    _vm._v(" refazer login")
                  ],
                  1
                ),
                _c(
                  "v-col",
                  { staticClass: "ma-auto", attrs: { lg: "10" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "font-weight-light body-2" },
                      [
                        _vm._v(
                          "Por medidas de segurança, todo acesso ao painel possui limite de tempo de inatividade, essas medidas são necessárias para garantir a segurança e o bom funcionamento do sistema dentro das normas mundiais de segurança"
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px" },
          model: {
            value: _vm.dialogSenhaExpirou,
            callback: function($$v) {
              _vm.dialogSenhaExpirou = $$v
            },
            expression: "dialogSenhaExpirou"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                {
                  staticClass:
                    "pt-5 primary-text text-center display-1 font-weight-bold"
                },
                [_vm._v("Ops. Sua Senha expirou :(")]
              ),
              _c(
                "v-row",
                { staticClass: "ma-auto text-center justify-center" },
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "10", lg: "10" } },
                    [
                      _c("v-card-text", { staticClass: "text-justify" }, [
                        _vm._v(" Você receberá um "),
                        _c("b", [_vm._v("e-mail")]),
                        _vm._v(
                          " com o link de redefinição de senha para criar um novo acesso. Essas medidas de segurança são necessárias para manter sua senha sempre atualizada. Na Recuperi, a segurança dos dados vem sempre em primeiro lugar. "
                        ),
                        _c("br"),
                        _c("br"),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.emailSenhaExpirada
                                ? `O e-mail será enviado para ${_vm.emailSenhaExpirada}`
                                : "O e-mail será enviado para o endereço fornecido em seu cadastro."
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  this.email != "" && /.+@.+/.test(this.email)
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "green darken-1",
                            text: "",
                            loading: _vm.loadingEnviaEmail
                          },
                          on: {
                            click: function($event) {
                              return _vm.enviaEmail(_vm.email)
                            }
                          }
                        },
                        [_vm._v("Confirmar")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogSenhaExpirou = false
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.dialogPrimeiroAcesso,
            callback: function($$v) {
              _vm.dialogPrimeiroAcesso = $$v
            },
            expression: "dialogPrimeiroAcesso"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-3" },
            [
              _c(
                "v-card-text",
                {
                  staticClass:
                    "pt-5 primary-text text-center display-1 font-weight-bold"
                },
                [_vm._v(" Esse é seu primeiro acesso, Vamos começar: ")]
              ),
              _c(
                "v-row",
                { staticClass: "ma-auto text-center justify-center" },
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "10", lg: "10" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c("v-icon", { staticClass: "ma-3" }, [
                            _vm._v("mdi-email")
                          ]),
                          _vm._v(
                            " Um e-mail será enviado para o endereço cadastrado com instruções para a criação de uma nova senha. "
                          )
                        ],
                        1
                      ),
                      _c("v-card-text", { staticClass: "text-center" }, [
                        _vm._v(
                          " Você receberá um link de redefinição de senha no seu e-mail cadastrado. Nosso sistema fornece uma senha inicial que precisa ser ajustada no primeiro acesso. Essas medidas de segurança são necessárias para manter sempre sua senha atualizada. Na Recuperi, a segurança dos dados vem sempre em primeiro lugar. "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "green darken-1 white--text" },
                      on: {
                        click: function($event) {
                          return _vm.enviaEmail()
                        }
                      }
                    },
                    [_vm._v(" Confirmar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogPrimeiroAcesso = false
                        }
                      }
                    },
                    [_vm._v(" Cancelar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: _vm.colorSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }